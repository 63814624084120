<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Send notification (Email/SMS)
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\  Send notifications
                </div>
              </div>
              <div class="breadcrumb-right">
                <v-btn @click="sendToRole()"
                       class="mt-4 btn btn-primary  mr-1"
                       dark>
                  <i class="fa fa-bell"></i>
                  Send notification
                </v-btn>
              </div>
            </div>
          </div>
          <div class="card-body">
        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
          <div class="row">
            <v-col cols="12" md="5">
              <v-text-field label="Title"
                            type="text"
                            dense
                            outlined
                            v-model="search.title"></v-text-field>
            </v-col>
            
            <v-col cols="12" md="3">
              <v-select label="Status" outlined dense :items="active_statuses" item-text="title"
                        item-value="value"
                        clearable
                        v-model="search.active"></v-select>
            </v-col>

            <v-col cols="12" md="4" class="text-right">
              <v-btn
                  @click="getNotifications()"
                  class="btn btn-primary btn-search "
                  :loading="loading"
              >
                <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                Search
              </v-btn>
            </v-col>
          </div>
        </div>
        <div class="">
          <v-skeleton-loader
              type="table-thead"
              v-if="loading"
          >
          </v-skeleton-loader>

          <v-skeleton-loader
              v-if="loading"
              type="table-row-divider@25"
          >
          </v-skeleton-loader>
          <table class="table">
            <thead class="text-left">
            <tr class="px-3">
              <th class="px-3 wrap-column">Subject</th>
              <th class="px-3 wrap-column">Type</th>
              <th class="px-3 wrap-column">Notification type</th>

              <th class="px-3 wrap-column">Send to</th>
              <th class="px-3 wrap-column">Date added</th>
              <th class="px-3 wrap-column">Schedule date</th>
              <th class="px-3 wrap-column">Time</th>
              <th class="px-3 wrap-column">Notified at</th>
              <th class="pr-3 text-center">Action</th>
            </tr>
            </thead>

            <tbody class="text-left" v-if="notifications.length>0">
              <tr v-for="(notification, index) of notifications" :key="index">
              <td class="px-3 wrap-column">
                <a href="#" class="navi-link" @click="editNotification(notification)">
                  {{ notification.subject }}
                </a>&nbsp;&nbsp;
                <i class="fas fa-circle" :class="notification.is_notified?'dot-active':'dot-inactive'"></i></td>
              <td class="px-3 wrap-column">{{ notification.type_text }}</td>
              <td class="px-3 wrap-column">{{ notification.notification_type?notification.notification_type.split('_').join(' ').toUpperCase():'-' }}</td>

              <td class="px-3 wrap-column" v-if="notification.notification_type == 'role'">
                <span   v-for="(ref, i) of notification.reference_value" :key="i">{{ ref }}<br></span>
              </td>
              <td class="px-3 wrap-column" v-else-if="notification.notification_type == 'mobile_or_email'">
                <span class="badge" v-for="(ref, i) of notification.reference_value" :key="i">{{ ref }} <br></span>
              </td>
              <td class="px-3 wrap-column" v-else>
                <span>-</span>
              </td>
              <td class="px-3 wrap-column">{{ notification.notification_created_at }}</td>
              <td class="px-3 wrap-column">{{ notification.display_send_date }}</td>
              <td class="px-3 wrap-column">{{ notification.display_send_at }}</td>
              <td class="px-3 wrap-column">{{ notification.notified_date ? notification.notified_date : 'N/A' }}</td>
                <td class="pr-0 text-center">
                <b-dropdown
                    size="sm"
                    variant="link"
                    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                    no-caret
                    right
                    no-flip
                >
                  <template v-slot:button-content>
                    <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                  </template>
                  <!--begin::Navigation-->
                  <div class="navi navi-hover ">


                    <b-dropdown-text tag="div" class="navi-item">
                      <a href="#" class="navi-link" @click="editNotification(notification)">
                                                            <span class="navi-icon">
                                                              <i class="flaticon-edit"></i>
                                                            </span>
                        <span class="navi-text"> Edit </span>
                      </a>
                    </b-dropdown-text>

                    <b-dropdown-text tag="div" class="navi-item">
                      <a href="#" class="navi-link"
                         @click="deleteNotification(notification.id)">
                                                            <span class="navi-icon">
                                                              <i class="fas fa-trash"></i>
                                                            </span>
                        <span class="navi-text">  Delete</span>
                      </a>
                    </b-dropdown-text>

                  </div>
                  <!--end::Navigation-->
                </b-dropdown>

              </td>
            </tr>
            </tbody>
            <tbody class="text-left" v-if="notifications.length==0">
              <tr v-if="notifications.length == 0">
                  <td  class="px-3 text-center" colspan="10">
                    <strong>
                      No data available to display.
                    </strong>
                  </td>
              </tr>
            </tbody>
          </table>
          <b-pagination
              v-if="notifications.length > 0"
              class="pull-right mt-7"
              @input="getNotifications"
              v-model="page"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
          ></b-pagination>
        </div>
      </div>
        </div>
      </div>
    </div>
    <create-or-update ref="notification" :dialog="dialog" :notification="notification"
                      @refresh_list="getNotifications"></create-or-update>
  </v-app>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import EmailNotificationService from "@/core/services/email-notification/EmailNotificationService";
import CreateOrUpdate from "./CreateOrUpdate"

const emailService = new EmailNotificationService();
export default {
  components: {CreateOrUpdate},
  data() {
    return {
      notifications: [],
      notification: {},
      active_statuses: [
        {title: 'Active', value: "active"},
        {title: 'Inactive', value: "in_active"}
      ],
      dialog: false,
      loading: false,
      notification_type: null,
      total: null,
      page: null,
      perPage: null, search: {
        active: "active"
      }
    };
  },
  mounted() {
    this.getNotifications();
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Notification", route: "wizard-1"},
      {title: "My Notification"}
    ]);

  },
  methods: {
    getNotifications(type) {
      this.loading=true;
      this.dialog = false
      emailService
          .paginate(this.search)
          .then(response => {
            this.notifications = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading=false;
          })
          .catch(error => {
            // console.log(error);
            this.loading=false;
          });
    },
    capitalize(string) {
      if (string) {
        string = string.charAt(0).toUpperCase() + string.slice(1);
        return string;
      }
    },
    openPage(page) {
      this.$refs["page"].showModal();
    },
    // editNotification(notification) {
    //   this.dialog = true
    //   // this.$router.push({name: "page-update", params: {id: page.id}});
    // },
    deleteNotification(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            emailService
                .delete(id)
                .then(response => {
                  this.$snotify.success("Information deleted");

                  this.getNotifications();
                })
                .catch(error => {
                  //console.log(error);
                });
          }
        }
      });
    }, editNotification(notification) {
      this.notification = notification
      this.$refs['notification'].assignNotification(notification)
      this.dialog = true
    },
    sendToRole() {
      this.dialog = true
    }
  }
};
</script>
