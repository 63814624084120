<template>
  <v-app>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            Send notification
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="closeDialog">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text>
          <v-row>

            <v-col cols="12">
              <v-text-field v-model="notification.subject" outlined dense label="Subject"></v-text-field>
              <span class="text-danger" v-if="$v.notification.subject.$error">This information is required</span>
            </v-col>
            <v-col cols="12">
              <v-textarea v-if="notification.type == 'bulk_sms'" v-model="message" outlined dense
                          label="Message"></v-textarea>
              <ckeditor v-else v-model="notification.message"></ckeditor>
              <span class="text-danger" v-if="$v.notification.message.$error">This information is required</span>
              <span class="text-danger" v-if="disable">Max character limit is 120.</span>
            </v-col>
            <v-col cols="12">
              <v-select label="Select notification type" :items="notification_types" item-value="value"
                        item-text="title" outlined dense v-model="notification.notification_type"></v-select>
            </v-col>
            <v-col cols="12" v-if="notification.notification_type=='role'">
              <v-select :items="roles" outlined dense v-model="notification.reference_value" label="Send to" multiple
                        item-text="name" item-value="name"></v-select>
            </v-col>
            <v-col cols="12" v-if="notification.notification_type=='mobile_or_email'">
              <v-combobox
                  v-model="notification.reference_value"
                  multiple
                  outlined dense
                  persistent-hint
                  small-chips
                  label="Please enter email addresses or phone"
                  hint="Please enter valid email or phone otherwise message will not be delivered to user"
              >
              </v-combobox>
            </v-col>
            <div class="col-4" v-if="notification.notification_type=='class'">
              <v-select label="Academic Year" :items="academicYears" item-value="id"
                        item-text="title" outlined dense v-model="notification.academic_year_id"
              ></v-select>
            </div>
            <div class="col-4"
                 v-if="notification.notification_type=='class' || notification.notification_type=='program'">
              <v-select label="Level" :items="levels" item-value="id"
                        item-text="title" outlined dense v-model="notification.level_id"
                        @change="getPrograms(notification.level_id)"></v-select>
            </div>
            <div class="col-4" v-if="notification.notification_type=='class'">
              <v-select label="Program" :items="programs" item-value="id"
                        item-text="title" outlined dense v-model="notification.program_id"
                        @change="getGrades(notification.program_id)"></v-select>
            </div>

            <div class="col-4" v-if="notification.notification_type=='program'">
              <v-select label="Program" :items="programs" item-value="id"
                        item-text="title" outlined dense v-model="notification.reference_value"
              ></v-select>
            </div>
            <div class="col-12" v-if="notification.notification_type=='level'">
              <v-select label="Level" :items="levels" item-value="id"
                        item-text="title" outlined dense v-model="notification.reference_value"
              ></v-select>
            </div>
            <div class="col-4" v-if="notification.notification_type=='class'">
              <v-select label="Grade" :items="gradesLevels" item-value="id"
                        item-text="title" outlined dense v-model="notification.grade_id"
                        @change="getAcademicClasses(notification.academic_year_id,notification.program_id,notification.grade_id)"></v-select>
            </div>
            <div class="col-4" v-if="notification.notification_type=='class'">
              <v-select label="Academic Class" :items="academic_classes" item-value="id"
                        item-text="title" outlined dense v-model="notification.reference_value"></v-select>
            </div>
            <v-col cols="4">
              <v-switch v-model="notification.is_instant" @change="setInstantTime"
                        label="Instant notification"></v-switch>
            </v-col>
            <v-col cols="4">
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      outlined
                      v-model="notification.send_date"
                      v-bind="attrs"
                      v-on="on"
                      label="Send date"
                      class="form-control"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                    v-model="notification.send_date"
                    header-menu="primary"
                    :min="min_value"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.notification.send_date.$error">Sending date is required</span>
            </v-col>
            <v-col cols="4">
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="notification.send_at"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      outlined dense
                      v-model="notification.send_at"
                      label="Scheduled for"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu2"
                    v-model="notification.send_at"
                    full-width
                    :min="min_time"
                    @click:minute="$refs.menu.save(notification.send_at)"
                ></v-time-picker>
              </v-menu>
              <!--                          <span class="text-danger" v-if="$v.notification.send_at.$error">Sending date is required</span>-->
            </v-col>

          </v-row>
<!--                    <pre>{{ notification }}</pre>-->
        </v-card-text>
        <v-card-actions>
          <div class="row">
            <div class="col-12 ">

              <v-btn
                  :disabled="disable"
                  class="text-white ml-2 btn btn-primary float-right"
                  depressed
                  @click="createNotification"
                  :loading="isBusy"
              >Save
              </v-btn>

              <v-btn
                  class="text-gray  ml-2  btn btn-standard float-right cancel-btn"
                  depressed
                  @click="closeDialog"
              >Cancel
              </v-btn>
            </div>
          </div>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import EmailNotificationService from "@/core/services/email-notification/EmailNotificationService";
import {required} from "vuelidate/lib/validators";
import RoleService from "@/core/services/user/role/RoleService";
import FilterMixin from "@/mixins/FilterMixin";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import ProgramService from "@/core/services/level/program/ProgramService";

const programService = new ProgramService();
const academicClassService = new AcademicClassService();
const emailService = new EmailNotificationService();
const roleService = new RoleService();
export default {
  name: "CreateOrUpdate",
  props: ['dialog'],
  mixins: [FilterMixin],
  validations: {
    notification: {
      message: {required},
      subject: {required},
      send_date: {required},
    }
  }, data() {
    return {
      menu: false,
      menu2: false,
      isBusy: false,
      edit: false,
      disable: false,
      message: null,
      roles: [],
      notification_type: 'send_to_role',
      filter: {},
      academic_class: {},
      notification: {
        notification_type: 'role',
        type: 'bulk_email',
        message: null,
        subject: null,
        send_date: null,
        send_at: null,
        reference_id: null,
        reference_value: null,
        is_instant: false
      },
      notification_types: [
        {title: 'Send by user type', value: 'role'},
        {title: 'Send by mobile or email', value: 'mobile_or_email'},
        // {title: 'Send to Selected Group', value: 'group'},
        {title: 'Send by class', value: 'class'},
        {title: 'Send by program', value: 'program'},
        {title: 'Send by level', value: 'level'},
      ]
    }
  },
  watch: {
    message(val) {
      this.disable = false
      if (val.length > 120)
        this.disable = true
    }
  }, computed: {
    min_value() {
      let date = new Date();
      return this.$moment(new Date).format('YYYY-MM-DD')
    },
    min_time() {
      let date = new Date();
      return this.$moment(date).add(15, 'minute').format('hh:mm')
    }
  },
  mounted() {
    this.getRoles()
    this.getAcademicYears();
    this.getLevels();
    this.setData();
  },
  methods: {
    getRoles() {
      let displayROles = ['student', 'teacher', 'staff'];
      roleService.all().then(response => {
        let roles = response.data.data
        roles.forEach(ele => {
          if (displayROles.includes(ele.name))
            this.roles.push(ele)
        })

      })
    },
    createNotification() {
      let ids = [];
      if (this.notification.type == 'bulk_sms') {
        this.notification.message = this.message
      }
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      } else {
        if (this.notification.academic_year_id)
          ids.push(this.notification.academic_year_id)
        if (this.notification.level_id)
          ids.push(this.notification.level_id)
        if (this.notification.program_id)
          ids.push(this.notification.program_id)
        if (this.notification.grade_id)
          ids.push(this.notification.grade_id)
        // if (ids.length > 0)
          this.notification.reference_id = ids
        if (this.edit) {
          this.update();
        } else {
          this.create();
        }
      }
    }, create() {
      emailService.store(this.notification).then(response => {
        this.$snotify.success("Notification Information added")
        this.resetNotification();
        this.$emit('refresh_list')
      })
    }, update() {
      this.notification.reference_id = this.viously_selected_id
      emailService.update(this.notification.id, this.notification).then(response => {
        this.$snotify.success("Notification Information updated")
        this.resetNotification();
        this.$emit('refresh_list')
      })
    }, resetNotification() {
      this.notification = {
        notification_type: 'role',
        type: 'bulk_email',
        message: null,
        subject: null,
        send_date: null,
        send_at: null,
        reference_id: null,
        reference_value: null,
      }
      this.edit = false
      this.$v.$reset();
    }, closeDialog() {
      this.resetNotification();
      this.$emit('refresh_list')
    },
    assignNotification(notification) {
      this.notification = notification
      this.edit = true
      if (this.notification.type == "bulk_sms") {
        this.message = this.notification.message
      }
      this.setData(notification);
    },

    setData(notification) {
      if (this.notification.notification_type == 'class') {
        this.getAcademicClassById(notification.reference_value)
      }
      if (this.notification.notification_type == 'program') {
        this.getProgramById(this.notification.reference_value)
      }
      if (this.notification.notification_type == 'level') {
        this.notification.reference_value = parseInt(this.notification.reference_value[0])
      }
    },

    getAcademicClassById(classId) {
      academicClassService.show(classId).then(response => {
        this.academic_class = response.data.class
        this.notification.academic_year_id = this.academic_class.academic_year_id
        this.notification.level_id = this.academic_class.level_id
        this.notification.program_id = this.academic_class.program_id
        this.notification.grade_id = this.academic_class.grade_id
        this.notification.reference_value = parseInt(this.notification.reference_value[0])
        this.getPrograms(this.notification.level_id)
        this.getGrades(this.notification.program_id)
        this.getAcademicClasses(this.notification.academic_year_id, this.notification.program_id, this.notification.grade_id)
      })
    },
    getProgramById(programId) {
      programService.show(programId).then(response => {
        this.program = response.data.program
        this.notification.level_id = this.program.level_id
        this.notification.reference_value = parseInt(this.notification.reference_value[0])
        this.getPrograms(this.notification.level_id)
      })
    },
    resetForm() {
      this.resetNotification();
    },
    setInstantTime() {
      if (this.notification.is_instant) {
        this.notification.send_date = this.$moment(new Date).format('YYYY-MM-DD');
        this.notification.send_at = this.$moment(new Date).add(5, 'minute').format('HH:mm')
      } else {
        this.notification.send_date = null;
        this.notification.send_at = null;
      }
    }
  }

}
</script>

<style scoped>

</style>
